var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"support-page","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('base-material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light text-center"},[_vm._v(" Техподдержка ")])]},proxy:true}])},[_c('div',{staticClass:"d-flex grow flex-wrap"},[_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v("Хотите получить доступ к инструментам?")]),_c('p',[_vm._v("Нашли ошибку или столкнулись с техническими сложностями?")]),_c('p',[_vm._v("Есть вопросы или рекомендации?")]),_c('p',[_vm._v("Свяжитесь с администратором, выбрав способ ниже:")])])],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('div',{staticClass:"input-group mb-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://wa.me/79122248711","target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"fab":"","dark":"","small":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)],1)]}}])},[_c('span',[_vm._v("Связаться в WhatsApp")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://t.me/NikolayKon","target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"fab":"","dark":"","small":"","color":"blue"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fab fa-telegram-plane")])],1)],1)]}}])},[_c('span',[_vm._v("Связаться в Telegram")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"viber://chat?number=79122248711","target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"fab":"","dark":"","small":"","color":"deep-purple lighten-2"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fab fa-viber")])],1)],1)]}}])},[_c('span',[_vm._v("Связаться в Viber")])])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }